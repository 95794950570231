@import 'styles/foundation';

.container {
  &.primary {
    background-color: $primaryBlue100;
    display: inline-block;
    padding: $px_4 $px_8;
    min-width: 20px;
    height: 20px;
    border-radius: $regularBorderRadius;
    color: $grey700;
  }

  @include body-xxs-medium;

  &.secondary {
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $systemColourBlack;
    color: $white;
  }

  &.tertiary {
    background-color: $primaryBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $px_4 $px_8;
    min-width: 20px;
    height: 20px;
    border-radius: $regularBorderRadius;
    color: $white;
  }
}

@import 'styles/foundation';

.container {
  width: 860px;
  height: 600px;
  display: flex;
}

.image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 380px;
  height: 100%;
  padding: $px_36;
}

.promo {
  margin-top: $px_16;
  @include body-s-regular;

  width: 238px;
}

.promoTitle {
  font-size: 18px;
}

.formContainer {
  width: 480px;
  height: 100%;
  padding: 30px 60px 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    padding: 30px 30px 37px;
  }
}

.welcomeBack {
  margin-bottom: $px_24;
  font-size: $mediumFontSize;
  line-height: $largeLineHeight;
  letter-spacing: 7.37px;
}

.markato {
  margin-bottom: $px_26;
}

.usernameContainer {
  margin-top: $px_20;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  margin-bottom: $px_16;
}

.forgotPassword {
  margin-top: -18px;
  color: $text;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.errorContainer {
  margin-bottom: $px_16;
  width: 100%;
}

.loginButton {
  margin-bottom: $px_16;
  font-weight: $mediumWeight;
}

.newContainer {
  margin-bottom: $px_8;

  span,
  a {
    font-size: $px_14;
  }
}

.new {
  line-height: 20px;
  font-size: $regularFontSize;
}

.spinner {
  position: absolute;
  top: 75%;
  left: 50%;
}

@include media-breakpoint-down(lg) {
  .container {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;

    // The image container
    .image {
      width: 100%;
      height: 600px;
      background-color: red;
      display: none;
    }

    // The form container
    .formContainer {
      width: 100%;
    }
  }
}

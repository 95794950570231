@import 'styles/foundation';

$inputHeight: 42px;

.container {
  width: 100%;
  margin-bottom: $formFieldMargin;
  position: relative;
}

.labelsWrapper {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 100%;
  border: 1px solid $systemColourBlack;
  padding: $px_10 $px_8 $px_10 $px_8;
  appearance: none;
  line-height: $superSmallLineHeight;
  font-style: normal;
  color: $secondaryText;
  border-radius: $regularBorderRadius;
  -webkit-appearance: none;
  background-color: transparent;

  @include regular-text;

  &:hover,
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $grey400;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  &:not(:disabled, .invalid):hover,
  &:not(:disabled, .invalid):focus {
    &::placeholder {
      color: $grey400;
    }
  }

  &.inputIconLeft {
    padding-left: $px_32;
  }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type='number'] {
  appearance: textfield;
}

.input[disabled] {
  background: $boxGrey;
  color: $borderGrey;
  border: 0;
  cursor: not-allowed;
}

.invalid {
  border: 1px solid $primaryRed;
  background: $invalid;
}

.iconRight {
  position: absolute;
  bottom: 0;
  right: $px_8;
  height: $inputHeight;
  display: flex;
  align-items: center;
  color: $grey500;

  svg {
    font-size: $almostLargeFontSize;
  }
}

.iconLeft {
  position: absolute;
  bottom: 0;
  left: $px_8;
  height: $inputHeight;
  display: flex;
  align-items: center;
  color: $grey500;

  svg {
    font-size: $almostLargeFontSize;
  }
}

.inputWrapper {
  position: relative;
}

.suffix {
  height: $inputHeight;
  min-width: $inputHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey500;
  border: 1px solid $grey200;
  border-left: 0;
  @include body-s-text;

  border-top-right-radius: $regularBorderRadius;
  border-bottom-right-radius: $regularBorderRadius;
}

.prefix {
  height: $inputHeight;
  min-width: $inputHeight;
  display: flex;
  align-items: center;
  color: $grey500;
  border: 1px solid $grey200;
  border-right: $px_0;
  @include body-s-text;

  border-top-left-radius: $regularBorderRadius;
  border-bottom-left-radius: $regularBorderRadius;
  box-sizing: border-box;
}

.prefixed {
  display: flex;

  .input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.suffixed {
  display: flex;

  .input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@import 'styles/foundation';

.btnAction {
  display: block;

  &:hover {
    cursor: pointer;
    background-color: $green300;
  }
}

@import 'styles/foundation';

.linkGroupRow {
  @include body-xs-regular;

  display: flex;
  justify-content: left;
  padding: $px_28 $px_80 $px_28;
  color: $logoLightGrey;
  max-width: $containerWidth;
  margin: 0 auto;

  @include media-breakpoint-down(lg) {
    text-align: left;
    flex-direction: column;
    gap: 1em;
    padding: $px_28;
  }

  span {
    padding: $px_0 $px_8;
    color: $logoLightGrey;
  }

  a {
    @include body-xs-regular;

    color: $logoLightGrey;
    border-left: 1px solid $logoLightGrey;
    padding: $px_0 $px_8;

    @include media-breakpoint-down(lg) {
      border: none;
    }
  }
}

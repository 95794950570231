@import 'styles/foundation';

.text {
  text-decoration: none;
}

.noUnderline {
  text-decoration: none !important;
}

.link {
  font-size: $mediumFontSize;
  line-height: $smallLineHeight;
  font-weight: $regularWeight;
  color: $link;

  &:hover {
    text-decoration: underline;
  }

  &.tertiary {
    color: $secondaryText;
  }
}

.button {
  font-weight: $mediumWeight;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  color: $liteText;
  background-color: $info;
  letter-spacing: 0.02em;

  &:hover {
    background-color: $hoverBlue;
  }

  &:active {
    background-color: $onClickBlue;
  }

  &:focus {
    background-color: $primaryBlue;
    box-shadow: rgba($onClickBlue, 1) 0 0 0 2px inset;
  }

  &.small {
    font-size: $mediumFontSize;
    padding: 6px $px_24;
    line-height: 20px;
  }

  &.medium {
    font-size: $semiLargeFontSize;
    padding: $px_8 $px_24;
    line-height: $px_24;
  }

  &.large {
    font-size: 18px;
    padding: $px_10 $px_24;
    line-height: $px_24;
  }

  &.secondary {
    color: $primaryBlue;
    background-color: transparent;
    box-shadow: rgba($primaryBlue, 1) 0 0 0 1px inset;

    &:hover {
      color: $hoverBlue;
      background-color: transparent;
      box-shadow: rgba($hoverBlue, 1) 0 0 0 1px inset;
    }

    &:active {
      color: $onClickBlue;
      background-color: transparent;
      box-shadow: rgba($onClickBlue, 1) 0 0 0 1px inset;
    }

    &:focus {
      color: $primaryBlue;
      background-color: transparent;
      box-shadow: rgba($primaryBlue, 1) 0 0 0 2px inset;
    }

    &:disabled {
      background-color: $white;
      box-shadow: rgba($borderGrey, 1) 0 0 0 1px inset;
      color: $darkGrey;
      cursor: not-allowed;
    }
  }

  &.tertiary {
    color: $secondaryText;
    background-color: $white;
    border: 1px solid $tertiaryText;

    &:hover {
      border-color: $secondaryText;
      background-color: $white;
    }

    &:active {
      border-color: $secondaryText;
    }

    &:focus {
      border-color: $secondaryText;
    }

    &.disabled {
      background-color: $boxGrey;
      color: $darkGrey;
      cursor: not-allowed;
    }

    &.red {
      color: $red700;
    }

    &.green {
      background-color: $green500;
    }
  }
}

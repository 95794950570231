@import 'styles/foundation';

.container {
  position: absolute;
  top: 50px;
  right: 48px;
  width: 517px;
  border: 1px solid $grey200;
  background-color: $white;
  border-radius: $regularBorderRadius;
  z-index: 100000;
  box-shadow: 0 0 20px rgb(0 0 0 / 12%);

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: $px_24;
    @include heading7-bold;

    border-bottom: 2px solid $grey200;

    img {
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: $px_16;
    border-top: 2px solid $grey200;

    .subtotal {
      @include body-m-medium;

      gap: $px_16;
      color: $secondaryText;
      margin-bottom: $px_16;

      span {
        @include heading7-bold;
      }
    }

    .markatoMinimum {
      display: flex;
      font-size: $px_16;
      gap: $px_16;
      margin-bottom: $px_16;
      align-items: center;

      .logo svg {
        color: $secondaryText;
        width: 58px;
      }
    }
  }

  .content {
    padding: $px_0 $px_24 $px_24 $px_24;
    max-height: 40vh;
    overflow: auto;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  &.hidden {
    display: none;
  }

  .error {
    @include body-xs-medium;

    color: $red500;
    margin-top: $px_16;
  }
}

@include media-breakpoint-down(lg) {
  .container {
    top: 50px;
    right: 0;
    width: 100%;
  }
}

@import 'styles/foundation';

.dropDown {
  background-color: $secondaryYellow1;
  border: none;
  box-sizing: border-box;
  padding: $px_16;
  border-radius: 6px;
  margin: $px_6 1em;
  filter: drop-shadow(-7px 24px 24.3px rgb(0 0 0 / 25%));
  min-width: 300px;
}

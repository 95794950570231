.refinement-list-list {
  max-height: 295px;
  overflow-y: auto;

  .refinement-list-item {
    margin-top: $px_20;
  }

  .refinement-list-label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }
  }

  .refinement-list-selectedItem {
    font-weight: $boldWeight;
  }

  .refinement-list-labelText {
    margin-left: $px_10;
    max-width: 175px;
    width: 100%;
  }

  .refinement-list-count {
    margin-left: auto;
    padding: $px_4 $px_6;
    font-size: $px_14;
    background-color: $silverLight;
    border-radius: $px_20;
    height: 100%;
    min-width: $px_36;
    display: table;
    text-align: center;
  }
}

.ais-SearchBox {
  margin-top: $px_20;

  .ais-SearchBox-form {
    display: flex;
    align-items: center;
  }

  .ais-SearchBox-input {
    @include body-m-medium;

    border: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: $secondaryYellow2;
    padding: $px_8;
    padding-left: $px_20;
    width: 100%;
    border-radius: $px_20;
    line-height: $px_20;
  }

  input::placeholder {
    color: $systemColourGrey2;
    opacity: 1; /* Firefox */
  }

  .ais-SearchBox-reset {
    display: none;
  }

  .ais-SearchBox-submit {
    margin-left: $px_12;
  }

  .ais-SearchBox-submitIcon {
    width: $px_16;
    height: $px_16;

    &:hover {
      fill: $black;
      cursor: pointer;
    }
  }
}

// All z-indexs must be defined here!
// This allows us to, at a glance, determine relative layers of features in our app.
// This will help prevent stupid z-index bugs arrising from arbitrary z-index values.
// Do not edit the z-index scale! Only add Feature scoped z-index values.

// Z-Index Scale (private vars)
// --------------------------------------------------
$zIndexMinus1: -100;
$zIndex1: 100;
$zIndex2: 200;
$zIndex3: 300;
$zIndex4: 400;
$zIndex5: 500;
$zIndex6: 600;
$zIndex7: 700;
$zIndex8: 800;
$zIndex10: 1000;
$zIndex100: 10000;

// Z-Index Features
// Format:
// $zIndex[SCALE][COMPONENT][CLASS]: $zIndex[SCALE];
// --------------------------------------------------

// Z INDEX -1
$zIndexMinus1Overlay: $zIndexMinus1;

// Z INDEX 1
$zIndex1AppHeader: $zIndex1;
$zIndexSidenav: $zIndex5;

// Z INDEX 2

// Z INDEX 3
$zIndex3Tooltip: $zIndex3;

// Z INDEX 4
$zIndex4OverlayModal: $zIndex4;
$zIndex5OverlayModal: $zIndex5;
$zIndex4Footer: $zIndex4;
$zIndex4Tooltip: $zIndex4;

// Z INDEX 5
$zIndex5OverlayToast: $zIndex5;
$zIndexSidebar5: $zIndex5;
$zIndex5Tooltip: $zIndex5;

// Z INDEX 6
$zIndex6Tooltip: $zIndex6;

// Z INDEX 7
$zIndex7OverlayToast: $zIndex7;
$zIndex7OverlayModal: $zIndex7;
$zIndex7OAppHeader: $zIndex7;

// Z INDEX 8
$zIndexSidebar8: $zIndex8;
$zIndex8Tooltip: $zIndex8;
$zIndexFullScreenDialog: $zIndex8;

// Z INDEX 10 - Dev/Debugging
$zIndex10Dev: $zIndex10;

// Z INDEX 11 - Dev/Debugging

// Z INDEX 1000 - ZenDesk Button Overlay
$zIndexZendesk: 999999;

@import 'styles/foundation';

.container {
  @include heading7-regular;

  background-color: $primaryBlue3;
  color: $white;

  .brandFont {
    font-family: $fontFamilyHero;
    font-size: 32px;
  }

  h5 {
    @include heading5-regular;
  }

  .link {
    @include heading7-regular;

    color: $primaryBlue100;
    text-decoration: none;
    display: inline-block;
  }

  .directory {
    display: flex;
    justify-content: space-between;
    padding: $footerSpacer $px_80 $footerSpacer;
    max-width: $containerWidth;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      padding: $px_60 $px_28;

      .logo {
        margin: 0 auto;
      }
    }

    .subscriptionWrapper {
      max-width: 512px;
      width: 100%;

      .emailSubscriptionDesc {
        margin-top: $px_24;
      }

      .newsLetterAction {
        margin-top: $px_24;
      }

      .sns {
        .link {
          font-size: $px_32;
          margin-right: 16px;
        }
      }
    }

    .linkGroupsWrapper {
      display: flex;
      margin-left: $px_80;
      gap: $px_48;

      @include media-breakpoint-down(lg) {
        margin-top: $px_36;
        margin-left: 0;
      }

      @include media-breakpoint-down(sm) {
        justify-content: space-between;
        flex-direction: column;
      }

      .linkGroupColumn {
        width: $px_200;

        @include media-breakpoint-down(lg) {
          width: auto;

          li a:first-child,
          li a:not(:first-child) {
            margin-top: 1em;
          }
        }

        a:first-child {
          margin-top: $px_12;
        }

        a:not(:first-child) {
          margin-top: $px_10;
        }
      }
    }
  }

  .footerLinkGroupRowWrapper {
    background-color: $primaryBlue2;
  }
}

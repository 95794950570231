@import 'styles/foundation';

.container {
  display: flex;
  line-height: $smallLineHeight;
  color: $primaryText;
  font-family: inherit;
  font-size: $regularFontSize;
  font-weight: $mediumWeight;
}

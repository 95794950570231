@import 'styles/foundation';

.modalWrapper {
  display: flex;
  width: 860px;
  height: 678px;
  max-width: 100%;

  .leftSideStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    height: 100%;
    padding-top: 42px;
    background: $grey50;

    .logoStyle {
      font-family: Marcellus, sans-serif;
      font-size: $largeFontSize;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
      letter-spacing: 2.86px;
      color: $secondaryText;
    }

    .imageStyle {
      margin: 130px 0 24px;
    }

    .titleStyle {
      font-size: $semiLargeFontSize;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #1a1a24;
    }
  }

  .rightSideStyle {
    display: flex;
    flex-direction: column;
    width: 55%;
    padding: $px_48 $px_60;

    .headingStyle {
      font-size: $largeFontSize;
      line-height: $largeLineHeight;
      font-weight: 500;
      text-transform: capitalize;
      padding-top: $px_8;
      padding-bottom: $formFieldMargin;
      letter-spacing: 1px;
    }

    .descriptionStyle {
      font-size: $mediumFontSize;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: $px_24;
    }

    .buttonWrapperStyle {
      position: absolute;
      bottom: $px_0;

      &:hover {
        background-color: #48484f;
      }
    }

    .resendWrapperStyle {
      .resendAction {
        display: flex;
        align-items: center;
      }

      .textStyle {
        font-size: $mediumFontSize;
        font-weight: 400;
        line-height: 22px;
      }

      .buttonResendStyle {
        padding: $px_8;
        background-color: transparent;
        color: $link;
      }
    }

    .remindTextStyle {
      font-size: $smallFontSize;
      color: $grey400;
      margin-top: $px_36;
      margin-bottom: $px_10;
      line-height: $superSmallLineHeight;
    }
  }

  .titleStyle {
    font-size: $semiLargeFontSize;
    font-weight: 500;
    line-height: 12px;
    font-style: normal;
    margin: $px_24 $px_0 $px_16 $px_0;
  }

  .passwordValidationWrapper {
    @include body-m-regular;

    margin-bottom: $px_16;

    .valid {
      span {
        background-color: $green500;
      }
    }

    .invalid {
      color: $grey300;

      span {
        background-color: $grey300;
      }
    }

    p {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: $px_16;
      }
    }
  }

  .errorMsgs {
    margin-top: $px_20;
    margin-bottom: $px_20;
  }

  .thankYouWrapperStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    padding-bottom: $px_60;

    .imageWrapperStyle {
      margin-bottom: $px_24;
    }

    .textStyle {
      font-size: $mediumFontSize;
    }
  }
}

@include media-breakpoint-down(lg) {
  .modalWrapper {
    flex-direction: column;
    width: 100%;
    height: auto;

    .leftSideStyle {
      width: 100%;
      background-color: transparent;
      flex-direction: column-reverse;

      .logoStyle {
        display: none;
      }

      .imageStyle {
        margin: 1em;
      }
    }

    .rightSideStyle {
      width: 100%;
      padding-top: 0;

      .buttonWrapperStyle {
        position: static;
      }

      .thankYouWrapperStyle {
        .textStyle {
          margin-bottom: 1em;
        }
      }
    }
  }
}

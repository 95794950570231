@import 'styles/foundation';

.container {
  position: relative;
  width: 100%;

  .panelWrapper {
    z-index: $zIndex100;
  }

  .inputWrapper {
    background-color: $secondaryYellow2;
    border: none;
    border-radius: $extraLargeBorderRadius;
  }
}

.sourceHeader {
  display: flex;
  margin: 0;
  margin-top: $px_16;
  color: $systemColourBlack;
  @include body-m-medium;

  padding-left: $autocompletePanelSideSpacing;
  padding-right: $autocompletePanelSideSpacing;
}

.brandIconHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $px_50;
  height: $px_50;
  background-color: $grey200;
}

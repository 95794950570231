@import 'styles/foundation';

.errorMessage {
  font-style: normal;
  font-weight: $regularWeight;
  font-size: $smallFontSize;
  line-height: $semiLargeFontSize;
  color: $secondaryRed;
  margin-top: $px_4;
  display: flex;

  .icon svg {
    color: $secondaryRed;
  }
}

.icon {
  font-size: $semiLargeFontSize;
  display: flex;
  margin-right: $px_8;
}

@import 'styles/foundation';

.tab {
  width: 100%;
  overflow: hidden;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .tabContent {
    max-height: 0;
    transition: all 0.15s;
  }

  input:checked {
    + .tabLabel {
      &::after {
        transform: rotate(90deg);
      }
    }

    ~ .tabContent {
      max-height: 100vh;
      padding: 0 $px_24;
    }
  }
}

@import 'styles/foundation';

.container {
  .success {
    max-width: 100%;
    background: #1a1a24c7;
    color: $white;
  }

  .error {
    max-width: 100%;
    background: $error100;
  }

  .warning {
    max-width: 100%;
    background: $warning100;
  }
}

.svgError {
  color: $red500;
  font-size: $semiLargeFontSize;
}

.svgWarning {
  color: $warning500;
  font-size: $semiLargeFontSize;
}

.dismissToast {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@import 'styles/foundation';

.container {
  background-color: $grey50;
  border-top: 1px solid $borderGrey;

  .content {
    padding: $px_20 80px;
    display: flex;
    gap: 18px;
    justify-content: center;
    align-items: center;
    max-width: $containerWidth;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: center;
      padding: $px_24;
    }
    @include media-breakpoint-down(sm) {
      font-size: $smallFontSize;
      line-height: $smallLineHeight;
    }
  }

  .cookieText {
    line-height: 1.4em;
    overflow-wrap: break-word;
    color: $systemColourBlack;

    a {
      color: $darkImperialBlue;
      font-size: inherit;
      text-decoration: underline;
    }
    @include media-breakpoint-down(md) {
      font-size: $mediumFontSize;
      line-height: $regularLineHeight;
    }
    @include media-breakpoint-down(sm) {
      font-size: $smallFontSize;
      line-height: $smallLineHeight;
    }
  }

  .cookieButtons {
    display: flex;
    align-items: center;
  }
}

@import 'styles/foundation';

.link {
  @include body-m-regular;

  display: flex;
  align-items: center;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  width: 100%;
  text-decoration: none;
  color: $systemColourBlack;
  padding-top: $px_12;
  padding-bottom: $px_12;
  padding-left: $autocompletePanelSideSpacing;
  padding-right: $autocompletePanelSideSpacing;

  &:hover {
    background-color: $whiteChocolate;
  }

  .image {
    margin-right: $px_12;
  }

  .itemTxt {
    margin-left: $px_12;
  }
}

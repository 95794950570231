@import 'styles/foundation';

$inputHeight: 32px;

.select {
  width: 100%;
  margin-bottom: $formFieldMargin;
  cursor: pointer;
  position: relative;

  [class$='-control'] {
    border-radius: $regularBorderRadius;
    border: 1px solid $systemColourBlack !important;
    background-color: transparent;
    min-height: $inputHeight;
    box-shadow: none;
  }

  [class*='__single-value'] {
    font-style: normal;
    font-weight: $regularWeight;
    font-size: $regularFontSize;
    line-height: $smallLineHeight;
    color: $primaryText;
    padding: $px_10 $px_8;
    margin: $px_0;
  }

  [class*='value-container'] {
    margin: $px_0;
    padding: $px_0;
  }

  [class*='input-container'] {
    margin: $px_0;
    padding: 0 0 0 $px_8;
  }

  [class$='-menu'] {
    border-radius: 6px;
    border: 1px solid $grey100;
    box-shadow: 0 0 20px rgb(0 0 0 / 12%);
    margin-top: $px_4;
    margin-bottom: $px_0;
  }

  [class*='-option'] {
    display: flex;
    align-items: center;
    margin: $px_0;
    padding: $px_8 $px_16;
    font-weight: $regularWeight;
    font-size: $regularFontSize;
    line-height: $smallLineHeight;
    color: $primaryText;
    background-color: $white;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  [class*='menu-list'] {
    padding: $px_10 $px_0;
  }

  [class*='control--menu-is-open'] {
    border-color: $primaryBlue;
  }

  [class*='control--is-focused'] {
    border-color: $borderGrey;

    &:hover {
      border-color: $borderGrey;
    }
  }

  .icon {
    padding: $px_10;
    line-height: $superSmallLineHeight;
    font-size: 10px;
  }

  [class*='is-selected'] {
    background-color: #f5f5f5;
  }

  [class$='-placeholder'] {
    color: $grey400;
    padding: $px_10 $px_8;
    line-height: $smallLineHeight;
    margin: $px_0;
    @include regular-text;
  }

  [class*='select__multi-value'] {
    margin-left: $px_8;
    background: $boxGrey;
    font-size: $regularFontSize;
    margin: $px_8 $px_0 $px_8 $px_8;
    border-radius: $regularBorderRadius;
  }

  [class*='select__multi-value__label'] {
    font-style: normal;
    align-items: center;
    font-weight: $regularWeight;
    font-size: $regularFontSize;
    line-height: $smallLineHeight;
    margin: 2px 0;
    text-align: center;
    display: flex;
    padding: $px_0;
    padding-left: $px_4;
  }

  [class*='select__multi-value__remove'] {
    background-color: transparent;
    color: black;
    cursor: pointer;
    margin: $px_0;
    margin-left: $px_4;
  }

  [class*='select__multi-value__remove']:hover {
    background-color: transparent;
  }

  [class*='__group-heading'] {
    &[class*='-group'] {
      text-transform: capitalize;
      color: $grey800;
      @include body-s-bold;

      margin: 0;
    }
  }

  [class*='-is-disabled'] {
    background-color: $disabled;
    border: 0;
    color: $borderGrey;
    cursor: not-allowed;
  }

  .invalid {
    [class$='-control'] {
      border: 1px solid $primaryRed;
      background: $invalid;
    }
  }

  .checkbox {
    width: 16px;
    height: 16px;
    margin: $px_0 $px_8 $px_0 $px_0;
    cursor: pointer;
    color: $white;
    border-radius: $regularBorderRadius;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    border: 1px solid $grey200;
    vertical-align: middle;
    text-align: middle;

    &:checked {
      background-color: $primaryBlue;
      border-color: $primaryBlue;
      position: relative;

      &::after {
        content: '';
        width: 4px;
        height: 7px;
        border: solid $white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        left: 5px;
        top: 3px;
      }
    }
  }

  [class*='select__group'] {
    margin: 0 8px;

    &:not(:first-child) {
      border-top: 1px solid $grey200;
    }
  }

  &.rounded {
    [class$='-control'] {
      border-radius: 20px;
      border: 1px solid #d2cfc6 !important;
    }
  }
}

.suffix {
  min-width: $inputHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey500;
  border: 1px solid $grey200;
  border-left: 0;
  @include body-s-text;

  &.miniCart {
    height: $inputHeight;
  }

  border-top-right-radius: $regularBorderRadius;
  border-bottom-right-radius: $regularBorderRadius;
}

.prefix {
  min-width: $inputHeight;
  display: flex;
  align-items: center;
  color: $grey500;
  border: 1px solid $grey200;
  border-right: $px_0;
  @include body-s-text;

  &.miniCart {
    height: $inputHeight;
  }

  justify-content: center;
  border-top-left-radius: $regularBorderRadius;
  border-bottom-left-radius: $regularBorderRadius;
  box-sizing: border-box;
}

.prefixed {
  display: flex;

  .input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.suffixed {
  display: flex;

  .input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.select.prefixed.suffixed {
  margin-bottom: 4px;

  [class$='-control'] {
    border-radius: 0;
    border: 1px solid $grey200 !important;
    background-color: transparent;
    box-shadow: none;
  }

  &.miniCart {
    svg {
      height: 12px;
      width: 12px;
    }

    [class$='-control'] {
      max-height: $inputHeight;
    }

    [class*='-is-disabled'] {
      max-height: $px_32;
    }

    [class*='__single-value'] {
      padding-top: 4px;
    }

    [class*='value-container'] {
      max-height: calc($inputHeight - $px_2);
    }

    [class*='cartItem__menu'] {
      z-index: 10;
    }
  }

  &:not(.miniCart) {
    [class*='value-container'] {
      min-height: 40px;
    }
  }
}

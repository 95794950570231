@import 'styles/foundation';

.rightSideContainer {
  width: 100%;
  height: 100%;
  padding: 0 62px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(md) {
    padding: 0 $px_30;
  }

  .signupForm {
    width: 100%;
    margin-top: $px_20;
  }
}

.signUpText {
  margin-top: 60px;
  margin-bottom: 28px;
  font-size: $mediumFontSize;
  letter-spacing: 7.37px;
}

.markatoText {
  margin-bottom: $px_26;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 2.86px;
  color: $text;
  font-family: Marcellus;
  font-weight: $regularWeight;
}

.haveAccount {
  margin-top: 17px;
  font-weight: $regularWeight;
  font-size: $mediumFontSize;
  line-height: $regularLineHeight;
  letter-spacing: -0.15px;
  color: $text;
  margin-bottom: 8px;
}

.applyToSellContainer {
  margin-top: $px_24;
  padding: 3px;

  .brand {
    line-height: 20px;
    font-size: $regularFontSize;
  }

  .applyToSell {
    color: $link;
    cursor: pointer;
    line-height: 20px;
    font-size: $regularFontSize;

    &:hover {
      text-decoration: underline;
    }
  }
}

.toasts {
  margin: $px_10 $px_0 $px_10 $px_0;
  min-height: $px_60;
}

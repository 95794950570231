@import 'styles/foundation';

.container {
  font-style: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-bottom: 0;

    span {
      font-weight: $regularWeight;
    }
  }
}

.label {
  padding-right: 10px;
}

.reverse label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  .label {
    padding-left: 10px;
  }
}

.button {
  height: $px_16;
  width: $px_16;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 1px solid $systemColourBlack;
  border-radius: $regularBorderRadius;
  cursor: pointer;

  &:focus {
    border: 2px solid $onClickBlue;
  }

  &:disabled {
    background-color: $tableGrey;
    border: 1px solid $borderGrey;
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $grey100;
  border: 1px solid $grey200;
  color: $grey100;
  line-height: 0;
}

.checked {
  background-color: $primaryBlue;
  border: 1px solid $primaryBlue;
  cursor: pointer;

  &:hover {
    background-color: $hoverBlue;
  }

  &:active {
    background-color: $onClickBlue;
  }

  &:focus {
    background-color: $onClickBlue;
  }

  &.disabled {
    background-color: $grey300;
    border: 1px solid $grey300;
    color: $white;
    cursor: not-allowed;

    svg {
      font-size: 10px;
    }
  }
}

.indeterminate {
  background-color: $primaryBlue;
  border: 1px solid $primaryBlue;
  cursor: pointer;

  span {
    color: $white;
  }

  &:hover {
    background-color: $hoverBlue;
  }

  &:active {
    background-color: $onClickBlue;
  }

  &:focus {
    background-color: $onClickBlue;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $grey100;
    border: 1px solid $grey200;
    color: $white;
  }
}

.icon {
  color: $white;
  font-size: $smallFontSize;
  line-height: 0;
}

.iconTransparent {
  opacity: 0;
  height: $px_10;
  width: $px_10;
  font-weight: 900;
  font-size: $smallFontSize;
  line-height: 14px;
}

@import 'styles/foundation';

.container {
  display: flex;
  align-items: center;

  [class*='input_container'] {
    width: auto;
    flex-grow: 1;
  }

  .underLineInput {
    color: $white;
    border: none;
    height: 54px;
    border-radius: 10px;
    background-color: $primaryBlue2;
    font-size: 14px;
    padding: 16px;
  }

  button {
    width: 74px;
    margin-left: 20px;
    margin-bottom: 20px;

    &.roundedBtn {
      color: $white;
      border-radius: 50%;
      border: none;
      background-color: $primaryBlue2;
      padding: 0;
      height: 54px;
      width: 54px;
      background-image: url('/images/icons/v3/newsletter-arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

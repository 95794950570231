@import 'styles/foundation';

.link {
  display: flex;
  align-items: center;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  width: 100%;
  text-decoration: none;
  color: $systemColourBlack;
  @include body-m-regular;

  padding-top: $px_12;
  padding-bottom: $px_12;
  padding-left: $autocompletePanelSideSpacing;
  padding-right: $autocompletePanelSideSpacing;

  &:hover {
    background-color: $whiteChocolate;
  }

  .itemLeftWrapper {
    display: flex;
    align-items: center;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #9ca3af;
    }

    .searchTxt {
      margin-left: $px_12;

      mark {
        background-color: inherit;
        font-weight: $boldWeight;
      }
    }
  }

  .actionGroupWrapper {
    margin-left: auto;

    .actionGroup {
      display: flex;
    }
  }
}

@import 'styles/foundation';

.leftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.rightContainer {
  background-color: $culturedGrey;
  padding: 30px 60px 37px;

  .title {
    @include heading1-bold;

    font-size: $px_36;
  }

  .desc {
    font-size: $px_20;
  }

  .subtitle {
    @include heading4-regular;

    margin-top: $px_28;
  }

  .pointList {
    list-style-type: disc;
    margin-top: $px_40;
    margin-left: 20px;

    & > li:not(:last-child) {
      margin-bottom: $px_30;
    }
  }

  .bnplAmountTxt {
    padding: $px_30 0;
    @include heading2-bold;
  }

  .btnGroup {
    margin-top: 40px;
  }

  .actionBtn {
    width: 100%;
    margin-bottom: $px_20;
    justify-content: center;
  }
}

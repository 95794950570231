@import 'styles/foundation';

.button {
  font-weight: $mediumWeight;
  border: $px_0;
  border-radius: $regularBorderRadius;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-apperance: none;
  -moz-apperance: none;
  letter-spacing: 0.02em;
  font-family: inherit;
}

.small {
  @include body-m-regular;

  padding: 5px $px_16;

  &.noLabel {
    padding: 3px 7px;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-left: $px_10;
    height: $px_16;
    width: $px_16;
  }

  .iconLeft {
    position: relative;

    svg {
      position: absolute;
      left: -10px;
      top: 0;
    }
  }
}

.medium {
  font-size: $semiLargeFontSize;
  padding: $px_8 $px_16;

  .icon {
    display: flex;
    align-items: center;
    margin-left: $px_10;
  }

  .iconLeft {
    margin-left: $px_0;
    margin-right: $px_10;
  }
}

.large {
  padding: $px_8 $px_16;
  font-size: $semiLargeFontSize;
  line-height: $px_24;
  width: 100%;
  justify-content: center;

  .icon {
    display: flex;
    align-items: center;
    margin-left: $px_10;
    font-size: $semiLargeFontSize;
  }

  .iconLeft {
    position: relative;
    margin-left: 0;

    svg {
      margin-right: 0.5em;
    }
  }
}

.noLabel {
  .icon {
    margin-left: $px_0;
    height: 24px;
  }
}

.primary {
  color: $liteText;
  background-color: $primaryBlue;

  &:hover {
    background-color: $primaryBlue700;
  }

  &:active {
    background-color: $onClickBlue;
  }

  &:focus {
    background-color: $primaryBlue;
  }

  &.disabled {
    background-color: $boxGrey;
    color: $darkGrey;
    cursor: not-allowed;
  }

  &.red {
    background-color: $red700;
  }

  &.green {
    background-color: $green500;
  }
}

.secondary {
  color: $white;
  background-color: $secondaryText;

  &:hover {
    background-color: $grey800;
  }

  &:active {
    background-color: $grey800;
  }

  &:focus {
    background-color: $grey800;
  }

  &.disabled {
    background-color: $boxGrey;
    color: $darkGrey;
    cursor: not-allowed;
  }

  &.red {
    color: $red700;
  }

  &.green {
    background-color: $green500;
  }
}

.tertiary {
  color: $secondaryText;
  background-color: $white;
  border: 1px solid $tertiaryText;

  &:hover {
    border-color: $secondaryText;
    background-color: $white;
  }

  &:active {
    border-color: $secondaryText;
  }

  &:focus {
    border-color: $secondaryText;
  }

  &.disabled {
    background-color: $boxGrey;
    color: $darkGrey;
    cursor: not-allowed;
  }

  &.red {
    color: $red700;
  }

  &.green {
    background-color: $green500;
  }
}

.ghost {
  color: $secondaryText;
  background-color: transparent;
  padding: $px_8 $px_0;
  @include body-s-medium;

  &.whiteLink {
    color: $white;
    text-decoration: underline;
  }

  &.blue {
    color: $blue;
  }

  &.disabled {
    color: $grey300;
    cursor: not-allowed;
  }

  .icon {
    margin-right: $px_8;
    height: 18px;
    font-size: 18px;
    justify-content: center;
  }

  .iconLeft svg {
    position: relative;
    left: auto;
    top: auto;
  }

  .red {
    color: $red700;
  }
}

.favouriteButton {
  padding: 0;

  &.disabled {
    color: $grey300;
    cursor: not-allowed;
  }

  .icon {
    margin-left: $px_8;
    margin-right: $px_8;
    height: 28px;
    width: 28px;
    font-size: 18px;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
    background-color: white;
  }

  .iconLeft svg {
    position: relative;
    left: auto;
    top: auto;
  }

  .red {
    color: $red700;
  }
}

.rounded {
  color: $secondaryYellow1;
  background-color: $systemColourBlack;
  border-radius: 999px;
  border: 2px solid $systemColourBlack;

  &:focus,
  &:hover,
  &:active {
    color: $systemColourBlack;
    background-color: $white;
  }

  &.disabled {
    background-color: $secondaryYellow1;
    color: $secondaryYellow1;
    cursor: not-allowed;
  }

  &.red {
    background-color: $red700;
  }

  &.green {
    background-color: $green500;
  }

  &.white {
    background-color: $white;
    color: $systemColourBlack;
  }

  &.secondary-yellow-1 {
    background-color: $secondaryYellow1;
    color: $systemColourBlack;
  }

  &.rounded--active {
    &:focus,
    &:hover,
    &:active {
      color: $secondaryYellow1;
      background-color: $systemColourBlack;
    }
  }

  &.rounded--inactive {
    color: $systemColourBlack;
    background-color: $white;

    &:focus,
    &:hover,
    &:active {
      color: $secondaryYellow1;
      background-color: $systemColourBlack;
    }
  }
}

.hasHoverIcon.medium {
  transition: all 0.4s;
  position: relative;

  .hoverIcon {
    display: none;
    position: absolute;
    right: $px_24;
    top: 11px;
  }

  &:hover {
    padding-left: $px_24;
    padding-right: calc($px_36 + ($px_36 - $px_24));

    .hoverIcon {
      display: inline-block;
    }
  }
}

@import 'styles/foundation';

/* ----------------- */

/*        arrows     */

/* ----------------- */
.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-position: center;
  width: $px_60 !important;
  height: $px_60 !important;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 40%);

  &:hover {
    background-color: $white;
  }

  &.swiper-button-disabled {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    width: $px_32 !important;
    height: $px_32 !important;
  }
}

.bestSellerCarousel {
  .swiper-button-prev,
  .swiper-button-next {
    top: 35%;
    background-color: $grey200;
    opacity: 0.6;

    @include media-breakpoint-down(xl) {
      top: 28%;
    }

    &:hover {
      background-color: $grey200;
      opacity: 0.9;
    }
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  background-image: url('/images/icons/arrow-right.svg');

  @include media-breakpoint-down(lg) {
    background-size: 25px 25px;
  }
}

.swiper-button-prev {
  background-image: url('/images/icons/arrow-left.svg');

  @include media-breakpoint-down(lg) {
    background-size: 25px 25px;
  }
}

.gallery .swiper-button-next {
  background-image: url('/images/icons/gallery-arrow-right.svg');
  background-color: rgb(0 0 0 / 40%);
}

.gallery .swiper-button-prev {
  background-image: url('/images/icons/gallery-arrow-left.svg');
  background-color: rgb(0 0 0 / 40%);
}

.gallery .swiper-slide-visible {
  border: 2px solid $white;
}

.gallery .swiper-slide-thumb-active {
  border: 2px solid $primaryBlue;
}

/* ----------------- */

/* Pagination  */

/* ----------------- */
.swiper-pagination-bullet {
  width: 36px !important;
  height: 4px !important;
  border-radius: $regularBorderRadius !important;
  color: $primaryBlue !important;
}

.swiper-pagination-bullet-active {
  background: $primaryBlue !important;
}

.swiper-pagination-keypoint {
  width: $containerWidth;

  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
  }
}

.swiper-pagination-keypoint-unbounded-width {
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
  }
}

/* ----------------- */

/*       wrapper     */

/* ----------------- */
.swiper-wrapper--rounded {
  border-radius: $px_20;
}

/* ----------------- */

/*        variants   */

/* ----------------- */
.mediaColumnSlider {
  width: 100vw;

  .swiper-pagination-bullet {
    background: $secondaryOrange2 !important;
  }

  .swiper-pagination-bullet-active {
    background: $secondaryYellow3 !important;
  }
}

/* ----------------- */

/*        variants   */

/* ----------------- */

.tile-swiper {
  .swiper-slide {
    width: auto;
  }
}

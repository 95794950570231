@import 'styles/foundation';

.statusInfoContainer {
  display: flex;
  flex-direction: row;

  .text {
    font-size: $mediumFontSize;
    line-height: $regularLineHeight;
    text-align: right;
    letter-spacing: -0.15px;
    color: $text;
    font-weight: $regularWeight;
    margin-top: 2px;
    margin-left: $px_4;
  }
}

@import 'styles/foundation';

.container {
  align-items: baseline;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid;
  transition: opacity 0.1 ease-in;
  width: 100%;
  border-radius: 4px;

  .message {
    display: flex;
    padding: $px_0 $px_8 $px_8 $px_0;

    .information p {
      font-size: $regularFontSize;
      font-weight: $regularWeight;
      line-height: $smallLineHeight;
      text-align: left;
    }

    &:first-child {
      padding-top: $px_8;
    }
  }
}

.information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: $smallLineHeight;
}

.info {
  background-color: $toastInfo;
  border-color: transparent;
  color: $primaryBlue;
}

.success {
  background-color: $toastSuccess;
  border-color: $primaryGreen;
  color: $primaryGreen;
}

.warning {
  background-color: $toastWarning;
  border-color: $primaryOrange;
  color: $primaryOrange;
}

.alert {
  background-color: $toastAlert;
  border-color: $toastAlertBorder;
  color: $primaryRed;
}

.icon {
  align-self: center;
  padding: $px_0 10px $px_0 13.5px;
  font-size: 15px;
}

.close {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

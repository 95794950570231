@import '../foundation';

html,
body {
  font-family: $fontFamily;
}

body {
  background-color: $secondaryYellow1;
}

h1 {
  font-weight: $mediumWeight;
  font-size: $superLargeFontSize;
}

h2 {
  font-weight: $regularWeight;
  font-size: $largeFontSize;
}

h3 {
  font-weight: $mediumWeight;
  font-size: $semiLargeFontSize;
}

h4 {
  font-weight: $regularWeight;
  font-size: $mediumFontSize;
}

h5 {
  font-weight: $regularWeight;
  font-size: $smallFontSize;
}

h6 {
  font-weight: $mediumWeight;
  font-size: $smallFontSize;
  color: $darkGrey;
}

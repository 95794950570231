@import 'styles/foundation';

.clickHandler {
  width: 100%;
}

.reference {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  width: 100%;
}

.arrow,
.arrow::before {
  background-color: $lightNavy;
  color: $lightNavy;
  position: absolute;
  width: $px_8;
  height: $px_8;
  z-index: -1;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.popper {
  z-index: 3;
}

[data-popper-placement^='top'] {
  .arrow {
    bottom: -$px_4;
  }
}

[data-popper-placement^='bottom'] {
  .arrow {
    top: -$px_4;
  }
}

[data-popper-placement^='left'] {
  .arrow {
    right: -$px_4;
  }
}

[data-popper-placement^='right'] {
  .arrow {
    left: -$px_4;
  }
}

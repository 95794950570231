@import 'styles/foundation';

.container {
  &.primary {
    color: $primaryBlue;
  }

  &.white {
    color: $white;
  }
}
@import 'styles/foundation';

.hit-product-list-sort-by-root {
  width: 100%;
}

.hit-product-list-sort-by-select {
  @include heading7-regular;

  display: flex;
  background-color: $secondaryYellow1;
  padding: $px_8 $px_20;
  border-radius: $px_40;
  color: $secondaryText;
  cursor: pointer;
  border: 1px solid $systemColourGrey1;
  height: $px_40;
  font-family: $fontFamily;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  width: 200px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('/images/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;

  @include media-breakpoint-down(lg) {
    @include heading7-semi-bold;

    width: 100%;
    text-align: center !important;
    padding-right: $px_36;
  }
}

.hit-product-list-sort-by-option {
  display: flex;
  align-items: center;
  margin: $px_0;
  padding: $px_8 $px_16;
  color: $primaryText;
  background-color: $white;

  &:hover {
    background-color: #f5f5f5;
  }
}

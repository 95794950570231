@import 'styles/foundation';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $px_20 $px_16;
  border-bottom: 1px solid $pastelGray;
  min-height: 76px;

  .closeButton {
    cursor: pointer;
  }

  .backButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -8px;

    .btnTitle {
      font-size: 16px;
      color: $systemColourBlack;
      margin-top: 2px;
      font-weight: 600;
    }
  }
}

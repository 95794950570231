@import 'styles/foundation';

.dialog {
  border: 0;
  border-radius: $px_6;
  overflow: auto;
  padding: 0;

  &::backdrop {
    background: $transparentGrey;
  }
}

.closeBtn {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
}

@include media-breakpoint-down(lg) {
  .dialog {
    width: 100%;
  }
}

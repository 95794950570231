@import 'styles/foundation';

.container {
  position: sticky;
  top: $px_0;
  z-index: $zIndex1AppHeader;
  display: flex;
  width: 100%;
  height: $px_36;
  background-color: white;
  justify-content: center;
  align-items: center;
  @include body-s-regular;

  .text {
    color: white;
    margin-left: $px_8;
  }

  .button {
    @include body-s-regular;

    margin-left: $px_24;
    color: white;
  }
}

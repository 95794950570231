@import './foundation';
@import './base';
@import './modules/carousel';
@import './modules/search';

.sticky-top {
  position: sticky;
  top: 0;
  left: 0;
  z-index: $zIndex10;
}

.cookie-banner {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: $zIndexZendesk;
}

html.page-brand-embed-widget-brandKey {
  background-color: transparent;
  font-size: 55px;

  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 48px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 390px) {
    font-size: 38px;
  }
  @media only screen and (max-width: 375px) {
    font-size: 37px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 35px;
  }

  body {
    background-color: transparent;
    overflow: hidden;
  }

  .sticky-top,
  .cookie-banner,
  #launcher,
  [class*='footer_container__woeEB'] {
    display: none !important;
  }
}

.autocomplete-container {
  .panel {
    overflow-y: scroll;
    z-index: 10;
    flex: 1 1 0%;
    color: #6b7280;
    background-color: #fff;

    @media (min-width: 1024px) {
      position: absolute;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      margin-top: 0.5rem;
      flex: none;
      border-radius: 0.375rem;

      --ring-color: #000;
      --ring-opacity: 0.05;

      max-height: 24rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      box-shadow:
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 4px 6px -2px rgb(0 0 0 / 5%);
    }
  }
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent !important;
}

// .carousel-gallery-photo-view {
//   .PhotoView__PhotoBox {
//     top: -25% !important;
//     left: -25% !important;
//   }
// }

@import 'styles/foundation';

.container {
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $systemColourGrey1;

  &.displayTopBanner {
    *[class*='navbar_subcategories'] {
      top: 167px;
    }
  }

  &:not(.displayTopBanner) {
    *[class*='navbar_subcategories'] {
      top: 125px;
    }
  }

  .navbar {
    display: flex;
    justify-content: center;
    background-color: $secondaryYellow1;
  }
}

@include media-breakpoint-down(lg) {
  .container {
    border-bottom: none;

    .navbar {
      display: none;
    }
  }
}

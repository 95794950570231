@import 'styles/foundation';

.container {
  width: auto;

  .label {
    display: flex;
    justify-content: space-between;
    @include body-xs-regular;

    svg {
      color: $green500;
      font-size: $almostLargeFontSize;
    }
  }

  &.reached .label {
    justify-content: flex-start;
    gap: $px_6;
  }
}

@import 'styles/foundation';

.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 50px;
  color: $secondaryText;

  &:hover {
    .itemWrapper {
      &:not(:hover) {
        opacity: 0.4;
      }

      &.itemWrapperDropdown,
      &:hover {
        opacity: 1;
      }
    }
  }

  .itemWrapper {
    padding: $px_24 19px;
    margin: $px_0 $px_0;
    border-radius: $smallBorderRadius;
    font-size: $mediumFontSize;
    cursor: pointer;
    color: $systemColourBlack;
    text-decoration: none;

    &.itemWrapperPromotion {
      padding: $px_8;
      margin: $px_0 $px_8;
      background-color: $primaryBlue50;
    }

    @include media-breakpoint-down(xl) {
      padding: $px_24 10px;
    }
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 8000px;
    background-color: rgb(0 0 0 / 40%);
    z-index: -$zIndex10;
  }
}

.subcategories {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: $floralWhite;
  top: 124px;
  left: 0;
  z-index: 1;
  box-shadow: 0 24px 24.3px 0 rgb(0 0 0 / 5%);
  border-radius: 0 0 2px 2px;
  padding: 18px 80px 30px;
  border-top: 1px solid #d2cfc6;

  .subcontent {
    max-width: 1280px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-size: 18px;

    .column {
      width: 25%;
      line-height: 1.1;

      .image {
        border-radius: $extraLargeBorderRadius;
        margin-bottom: $px_10;
      }

      ul {
        margin-bottom: 24px;

        li {
          width: 90%;
        }

        & > li:first-child {
          margin-bottom: 8px;
        }

        &.withoutChildren {
          margin-bottom: 0;

          & > li:first-child {
            margin-bottom: 10px;
          }
        }

        &:last-child {
          margin-bottom: 0;

          li:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.withoutChildren {
        margin-bottom: 8px;

        & > li:first-child {
          margin-bottom: 8px;
        }
      }
    }

    .linkL2Text {
      color: $systemColourBlack;
      font-size: 16px;
      line-height: 2;
      font-weight: $semiBoldWeight;
      margin-bottom: 10px;
      text-decoration: none;

      &.noUrlText {
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: underline;
      }

      &.withoutChildren {
        margin-bottom: 8px;
      }
    }

    .l3ListItem {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .l3Link {
        color: $systemColourBlack;
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .separator {
      margin: 16px 0;
      border-top: 1px solid $systemColourGrey1;
      width: 90%;
    }
  }
}

@import 'styles/foundation';

.passwordValidationWrapper {
  @include body-m-regular;

  margin-top: -8px;
  margin-bottom: $px_16;

  .valid {
    span {
      background-color: $green500;
    }
  }

  .invalid {
    color: $grey300;

    span {
      background-color: $grey300;
    }
  }

  p {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: $px_16;
    }
  }
}

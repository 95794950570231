@import 'styles/foundation';

.container {
  margin-bottom: $px_8;
  font-size: $mediumFontSize;
  line-height: $regularLineHeight;
  letter-spacing: -0.15px;
  display: flex;
}

.dash {
  width: 19px;
  height: 1px;
  border-top: 1px solid $tertiaryText;
  margin: 11px 14px 0;
}

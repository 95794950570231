@import 'styles/foundation';

.leftSideContainer {
  width: 381px;
  height: 100%;
  position: relative;
}

.sideText {
  position: absolute;
  text-align: left;
  width: 237px;
  top: 59px;
  left: 72px;
}

.sideTextHeading {
  margin-bottom: $px_16;
  font-weight: $mediumWeight;
  font-size: 18px;
  letter-spacing: 0.37px;
  color: $text;
}

.sideTextDescription {
  font-weight: $regularWeight;
  font-size: $regularFontSize;
  line-height: $smallLineHeight;
  color: #4d4d54;
}

.sideImage {
  width: 381px;
  height: 100%;
}

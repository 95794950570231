@import 'styles/foundation';

.container {
  margin-top: $px_16;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: $grey50;
  border-radius: $regularBorderRadius;
  padding: $px_8 $px_10;
  align-items: center;
  gap: $px_12;
  flex-wrap: wrap;
  @include body-m-bold;

  .brandData {
    display: flex;
    align-items: center;
    gap: $px_8;
  }

  .brandName {
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: block;

    a {
      color: $secondaryText;
      text-decoration: none;
    }
  }

  .big {
    max-width: 500px;
  }

  .country {
    @include body-s-regular;
  }
}

@import 'styles/foundation';

.container {
  display: flex;
  gap: $px_16;
  border-bottom: 1px solid $grey100;
  padding: $px_16 $px_0;

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h4 {
      a {
        text-decoration: none;
        color: $primaryText;
      }

      max-width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      @include body-m-medium;

      color: $secondaryText;
      margin-bottom: $px_10;
    }

    .counter {
      width: 120px;
      color: $grey500;
      @include body-xs-regular;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      align-items: flex-end;
      gap: $px_16;

      span {
        display: inline-block;
        margin-bottom: $px_6;
      }

      .quantityChanger {
        width: 150px;
        display: flex;
        justify-content: center;
        gap: $px_4;
      }

      .quantityText {
        white-space: nowrap;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: $secondaryText;
    align-items: flex-end;
    @include body-m-medium;
  }
}

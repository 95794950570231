@import 'styles/foundation';

.autocomplete-panel {
  background-color: $secondaryYellow2;
  filter: drop-shadow(-7px 11px 24.3px rgb(0 0 0 / 25%));
  color: #6b7280;
  position: absolute;
  top: 50px !important;
  left: 0 !important;
  right: 0 !important;
  height: 40vh;
  overflow-y: scroll;
  border-radius: $largeBorderRadius;
  z-index: $zIndex10;

  .autocomplete-label {
    display: flex;
  }

  .aa-List {
    display: grid;
    margin-top: $px_16;

    &:last-child {
      margin-bottom: $px_16;
    }
  }
}

.autocomplete-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: $px_8;
  padding-left: $px_10;
  flex: 1;
  width: 100%;
  font-style: normal;
  color: $secondaryText;
  border-radius: $extraLargeBorderRadius;
  @include body-s-text;

  *[class*='aa-SubmitIcon'] {
    fill: black;
  }

  .autocomplete-inputWrapperPrefix {
    margin-right: $px_10;
  }

  .autocomplete-clearButton {
    display: none;
  }

  .autocomplete-inputWrapper {
    flex: 1;

    /* clears the ‘X’ from Internet Explorer */
    input[type='search']::-ms-clear,
    input[type='search']::-ms-reveal {
      display: none;
    }

    /* clears the ‘X’ from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .autocomplete-input {
      display: block;
      border: none;
      width: 100%;
      background-color: $secondaryYellow2;
      appearance: none;
      -webkit-appearance: none;

      &:hover,
      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $borderGrey;
      }

      @media (min-width: 640px) {
        font-size: 14px;
        line-height: 1.25;
      }
    }
  }

  .autocomplete-inputWrapperSuffix {
    margin-left: auto;
  }
}

@import 'styles/foundation';

.container {
  width: 860px;
  height: 600px;
  display: flex;
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;
  }

  .image {
    width: 380px;
    height: 100%;

    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 600px;
      display: none;
    }
  }

  .formContainer {
    width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

@import 'styles/foundation';

.container {
  width: 100%;

  svg:hover {
    cursor: help;
  }
}

.text {
  padding: $px_8;
  color: $whiteText;
  background-color: $lightNavy;
  border-radius: 3px;
  font-size: $regularFontSize;
  font-weight: $regularWeight;
  white-space: pre-line;
  line-height: 1.8em;

  ul {
    margin-left: 2em;
  }

  ul,
  li {
    list-style: disc;
  }
}

.small {
  font-size: $tinyFontSize;
}

.large {
  font-size: $mediumFontSize;
}

.popup {
  display: flex;
  align-items: center;
  z-index: $zIndex8Tooltip;
  max-width: 270px;
}

@import 'styles/foundation';

.container {
  width: 420px;
  padding: 40px $px_32;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginTo {
  margin-bottom: $px_24;
  font-size: $mediumFontSize;
  line-height: $superSmallLineHeight;
  letter-spacing: 7.37px;
}

.markato {
  margin-bottom: $px_32;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 2.86px;
  color: $secondaryText;
  font-family: Marcellus;
  font-weight: $regularWeight;
}

.loginButton {
  margin-bottom: $px_16;
  font-weight: $mediumWeight;
  height: 48px;
}

.brandButton {
  margin-bottom: $px_24;
  font-weight: $mediumWeight;
  height: 48px;
}

@include media-breakpoint-down(lg) {
  .container {
    width: 100%;
  }
}

@import 'styles/foundation';

.container {
  position: static;
  top: $px_0;
  display: flex;
  width: 100%;
  height: fit-content;
  padding: $px_10 $px_6;
  background-color: white;
  justify-content: center;
  align-items: center;
  @include body-s-regular;

  font-size: 14px;

  .link {
    margin-left: 4px;
    cursor: pointer;
  }
}

.spinner {
  left: 50%;
  margin-top: $px_6;
}

@include media-breakpoint-down(lg) {
  .container {
    text-align: center;
    line-height: 1.4em;
    font-size: $smallFontSize;
  }
}

@import 'styles/foundation';

.search-hits-root {
  margin-bottom: $px_48;

  .search-hits-list {
    display: grid;
    grid-template-columns: [start] repeat(4, minmax(0, 1fr)) [end];
    grid-gap: $px_16;
  }
}

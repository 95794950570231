@import 'styles/foundation';

.outter {
  background-color: $secondaryYellow1;
  border-bottom: 1px solid $systemColourGrey1;

  .container {
    display: flex;
    width: 100%;
    max-width: $containerWidth;
    margin: auto;
    align-items: center;
    padding: $px_16 $px_80;
    position: relative;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      width: 100%;
      padding: $px_20 $px_24;
      padding-bottom: $px_16;
    }

    @include media-breakpoint-down(xs) {
      *[class*='_logo_'] svg {
        width: 54px;
      }

      *[class*='_logo_'] img {
        width: auto;
        height: 100%;
      }
    }

    .searchWrapper {
      display: flex;
      align-items: center;
      margin: 0 $px_16;
      width: 550px;

      @include media-breakpoint-down(lg) {
        order: 3;
        width: 100%;
        margin: 0;
        margin-top: $px_24;
      }

      @include media-breakpoint-down(sm) {
        margin-top: $px_12;
      }
    }

    .rightSideWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 330px;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .borderlessButton {
        background-color: transparent;
        padding: $px_10;
        border: none;
        height: $px_32;
        display: flex;
        align-items: center;
        color: $systemColourBlack;
        font-weight: normal;

        &.regularPerson {
          [class*='button_iconLeft'] {
            height: 24px;
            width: 24px;
            justify-content: center;

            svg {
              position: static;
            }
          }
        }

        &.white {
          color: $white;
        }

        &.signup {
          margin-left: 16px;
        }

        &.langSwitcher {
          span {
            margin-left: 0.2em;
            font-size: 14px;
          }
        }
      }

      .getButton {
        color: $primaryBlue;
        padding: $px_4 $px_16;
        border: none;
        margin: $px_4;
      }

      .saveButton {
        width: 100%;

        span {
          width: 100%;
        }
      }

      .links {
        border: none;
        padding: $px_10;
        font-size: 24px;
        line-height: 1em;
        height: $px_32;
        display: flex;
        align-items: center;
        background-color: transparent;

        svg {
          display: flex;
        }
      }

      .button {
        padding: $px_4 0;
        margin: 0;
        margin-right: $px_36;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          box-shadow: none;
        }

        &.primary {
          border-radius: 4px;
          background-color: $beigeCtaColor;
          color: #33333a;
          border: none;
          padding: $px_4 $px_16;
          margin-right: 0;
          height: $px_32;
        }
      }
    }

    .cartButton {
      position: relative;
      cursor: pointer;
      display: flex;

      .notification {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .userMenu {
      width: 310px;
      border-radius: $regularBorderRadius;
      background: $white;
      box-shadow: 0 0 20px rgb(0 0 0 / 12%);

      .menuItem {
        padding: 0 $px_20;
        line-height: $superSmallLineHeight;
        vertical-align: middle;
        font-size: $mediumFontSize;

        button {
          font-size: inherit;
          color: inherit;
          cursor: pointer;
        }

        &.paddingTop {
          padding-top: $px_16;
        }

        &.paddingBottom {
          padding-bottom: $px_16;
        }

        span {
          &.iconWrapper {
            padding-right: $px_10;
          }

          svg {
            vertical-align: middle;
            color: $secondaryText;

            &.personIcon {
              margin-top: $px_6;
            }
          }
        }

        .menuEmail {
          margin-top: $px_4;
          font-size: $smallFontSize;
          color: $grey400;
        }

        a {
          text-decoration: none;
          color: $secondaryText;
        }

        .paymentTermsIcon {
          color: $link;
        }

        .paymentTermsLink {
          color: $link;
        }

        .unlockTerms {
          @include body-m-regular;

          span {
            @include body-m-bold;

            color: $beige300;
          }
        }
      }

      .horizontalSeparator {
        border-top: 1px solid $grey100;
        padding: 0;
        margin: 0;
      }
    }

    .mobileMenuWrapper {
      display: none;
    }
  }

  .mobileMenu {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .outter {
    &.isSticky {
      .container {
        *[class*='_logo_'] svg {
          width: 54px;
        }

        *[class*='_logo_'] img {
          width: auto;
          height: 100%;
        }
      }
    }

    &.notSticky {
      height: $mobileHeaderHeight;
    }

    .container {
      .logo {
        flex-grow: 0;
        width: auto !important;
      }

      .mobileMenuWrapper {
        display: flex;
        align-items: center;
        gap: $px_16;

        .cart {
          position: relative;
          cursor: pointer;

          &.hasItem {
            margin-right: $px_8;
          }

          .notification {
            position: absolute;
            top: -5px;
            right: -10px;

            span {
              font-size: 10px;
              line-height: 18px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .mobileMenuWrapper {
      img,
      button {
        cursor: pointer;
      }

      button {
        border: none;
      }

      .menuButton {
        button {
          background-color: transparent;
          color: $white;
          font-size: $almostLargeFontSize;
          padding: 0;
        }
      }
    }

    .mobileMenu {
      display: block;
      position: fixed;
      background: $secondaryYellow1;
      z-index: $zIndex10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      animation: fade-in 200ms ease-in;

      .borderlessButton {
        background-color: transparent;
        justify-content: flex-start;
        color: #33333a;
        padding: 18px 16px;
        margin-left: -4px;

        .langText {
          svg {
            margin-right: 6px;
          }
        }
      }

      .userMenu {
        border-bottom: 1px solid $grey100;
        font-size: $mediumFontSize;
        color: $text;

        *[class*='_horizontalSeparator_'] {
          border-bottom: 1px solid $pastelGray;
          display: block;
          width: 100%;
          margin: 0;
        }

        .menuItem {
          padding: $px_10 0;
          margin: 0 16px;

          .iconWrapper {
            margin-top: -6px;
          }

          a,
          button {
            display: flex;
            align-items: center;
            font-size: $mediumFontSize;
            color: $text;
            text-decoration: none;
          }

          button {
            cursor: pointer;
          }

          a > .iconWrapper,
          button > .iconWrapper,
          div > .iconWrapper {
            vertical-align: middle;
            margin-right: 8px;
            margin-top: 5px;
          }

          *[class*='_unlockTerms_'] {
            font-size: $mediumFontSize;
            line-height: 1.5em;
            color: $text;
            white-space: break-spaces;
            display: inline-block;

            span {
              color: $beige300;
              margin-right: 0 !important;
              vertical-align: baseline;
            }
          }

          div[class*='_menuEmail_'] {
            color: $placeholderText;
            font-size: 12px;
            line-height: 1em;
            margin: 0;
            margin-top: 0.5em;
          }
        }
      }

      .offSessionMenu {
        padding: $px_10 $px_16;
        display: flex;
        flex-direction: column;
        margin-top: $px_16;
        padding-bottom: 200px;

        li {
          font-size: $mediumFontSize;
          padding: $px_20 0;
          display: flex;
          justify-content: center;
          cursor: pointer;
          border: 1px solid $primaryBlue;
          margin-bottom: $px_20;
          border-radius: 4px;

          &:last-child {
            background-color: $beigeCtaColor;
            border: 1px solid $beigeCtaColor;
          }
        }
      }

      .mobileNavigation {
        &.extraPadding {
          padding-bottom: 500px;
        }

        > ul {
          border-bottom: 1px solid $pastelGray;
        }

        .slideMenu {
          padding-bottom: 500px;

          .topLevelSection {
            padding: 18px 16px 16px;

            .navTitle {
              font-size: 21px;
              line-height: 1.25;
            }

            .topLevelLinkList {
              display: grid;
              flex-direction: column;
              gap: 20px;
              margin-top: 18px;
            }
          }

          .bottomSection {
            &:last-child {
              border-bottom: 1px solid $pastelGray;
            }
          }
        }

        .tab {
          border-top: 1px solid $pastelGray;

          .tabLabel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            font-size: $mediumFontSize;
            padding: $px_18 $px_16;
            position: relative;

            &::after {
              position: absolute;
              content: url('../../../../public/images/icons/arrow-chevrons/chevron-smooth-right.svg');
              text-align: center;
              transition: all 0.15s;
              color: $black;
              font-weight: normal;
              top: 30%;
              right: 12px;
            }
          }

          .tabContent {
            color: $grey500;
            font-size: $mediumFontSize;
            padding: 0 16px;

            a {
              color: $systemColourBlack;

              &:hover {
                color: $black;
              }
            }

            .tabContentInnerWrapper {
              gap: 20px;
              margin-left: 32px;
            }

            .tabContentItem {
              padding-bottom: 24px;
            }
          }
        }

        a,
        label {
          color: $systemColourBlack;
          text-decoration: none;
          line-height: 1.5;
        }
      }
    }

    .slideMenu {
      display: block;
      position: fixed;
      background: $secondaryYellow1;
      z-index: $zIndex10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
}

.outter {
  @include media-breakpoint-down(xs) {
    &.notSticky {
      height: 115px;
    }
  }
}

@import 'styles/foundation';

.avatar {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: $semiBoldWeight;
  font-size: $mediumFontSize;
  line-height: $smallLineHeight;
  color: $whiteText;
  position: relative;
  cursor: pointer;
}

.avatarImage {
  border-radius: 100%;
}

.noImage {
  background-color: $primaryBlue;
}
